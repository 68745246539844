.button {
  font: inherit;
  border: 1px solid #191c4a;
  background: #191c4a;
  color: #ffffff;
  padding: 0.25rem 1rem;
  cursor: pointer;
}

.button:hover,
.button:active {
  background: #191c4a;
  border-color: #191c4a;
}

.button:focus {
  outline: none;
}
