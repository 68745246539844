.button {
  font: inherit;
  border: 1px solid #a80404;
  background: #a80404;
  color: #fff;
  padding: 0.25rem 1rem;
  cursor: pointer;
}

.button:hover,
.button:active {
  background: #a80404;
  border-color: #a80404;
}

.button:focus {
  outline: none;
}
