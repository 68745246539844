.button {
  font: inherit;
  border: 1px solid #fcb900;
  background: #296f24;
  color: #fff;
  padding: 0.25rem 1rem;
  cursor: pointer;
}

.button:hover,
.button:active {
  background: #296f24;
  border-color: #296f24;
}

.button:focus {
  outline: none;
}
