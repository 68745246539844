.button {
  font: inherit;
  border: 1px solid #ee7e01;
  background: #ee7e01;
  color: #fff;
  padding: 0.25rem 1rem;
  cursor: pointer;
}

.button:hover,
.button:active {
  background: #ee7e20;
  border-color: #ee7e20;
}

.button:focus {
  outline: none;
}
