.button {
  font: inherit;
  border: 1px solid #763984;
  background: #763984;
  color: white;
  padding: 0.25rem 1rem;
  cursor: pointer;
}

.button:hover,
.button:active {
  background: #763980;
  border-color: #763980;
}

.button:focus {
  outline: none;
}
