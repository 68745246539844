.button {
  font: inherit;
  border: 1px solid #ffc52a;
  background: #ffc52a;
  color: #4a4a4a;
  padding: 0.25rem 1rem;
  cursor: pointer;
}

.button:hover,
.button:active {
  background: #ffc88a;
  border-color: #ffc88a;
}

.button:focus {
  outline: none;
}
