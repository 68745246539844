/* *,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
} */

body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 1rem;
  font-weight: normal;
  line-height: 1.5;
  color: #252a32;
  background: #f1f5f8;
}

a,
button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  text-decoration: none;
}

img {
  /* display: block; */
  /* width: 100%; */
  /* height: auto; */
}

.container {
  max-width: 80rem;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding: 0 2rem;
}

.brand {
  font-family: inherit;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: inherit;
  color: #148cb8;
  text-transform: uppercase;
}

.header {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  /* z-index: 999; */
  color: #252a32;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);
}
.header .navbar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
}
.header .navbar .burger {
  position: fixed;
  top: 1.75rem;
  left: 2rem;
  z-index: 9;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  transition: left 0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91);
}
.header .navbar .burger-close {
  display: none;
}
.header .navbar .burger.active {
  left: 14rem;
}
.header .navbar .burger.active .burger-open {
  display: none;
}
.header .navbar .burger.active .burger-close {
  display: block;
}
.header .navbar .menu {
  position: fixed;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 8;
  opacity: 0;
  top: 0rem;
  left: -20rem;
  width: 20rem;
  height: 100%;
  color: #252a32;
  background: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.24);
  transition: all 0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91);
}
.header .navbar .menu-item {
  width: 100%;
  padding: 1rem 2rem;
  border: none;
  outline: none;
  transition: all 0.5s ease;
}
.header .navbar .menu-item a {
  font-family: inherit;
  font-size: 1rem;
  /* font-weight: 400; */
  line-height: inherit;
  color: #252a32;
  /* text-transform: uppercase; */
}
.header .navbar .menu-item:hover {
  padding: 1rem 3rem;
  background: #296f24;
}
.header .navbar .menu-item:hover a {
  color: #fff;
}
.header .navbar .menu.active {
  left: 0;
  opacity: 1;
}

.main .wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
