.button {
  font: inherit;
  border: 1px solid #2b5a9b;
  background: #2b5a9b;
  color: #fff;
  padding: 0.25rem 1rem;
  cursor: pointer;
}

.button:hover,
.button:active {
  background: #2b5a9b;
  border-color: #2b5a9b;
}

.button:focus {
  outline: none;
}
